@font-face {
  font-family: 'Raleway';
  src: url('/assets/fonts/Raleway/Raleway-VariableFont_wght.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('/assets/fonts/Raleway/Raleway-Italic-VariableFont_wght.ttf')
    format('truetype');
  font-weight: 1 999;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-LightItalic.ttf')
    format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf')
    format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf')
    format('truetype');
  font-weight: 800;
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 62.5%; /* ~10px, curious why? see this: https://engageinteractive.co.uk/blog/em-vs-rem-vs-px */
}

@media (min-width: 120em) {
  html {
    /* font-size: 68.75%; */
  } /* ~11px */
}
@media (min-width: 160em) {
  html {
    /* font-size: 75%; */
  } /* ~12px */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.3);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.3);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.3);
}

/* Wallet Modal */
.web3modal-modal-lightbox {
  z-index: 100 !important;
}
.web3modal-modal-card {
  font-family: 'Open Sans';
  border-radius: 20px;
  width: 550px !important;
  max-width: 550px !important;
}
.web3modal-modal-card > a {
  text-decoration: none;
}
.web3modal-modal-card .web3modal-provider-wrapper {
  border: none;
}
.web3modal-modal-card .web3modal-provider-container {
  height: 250px;
}
.web3modal-modal-card .web3modal-modal-card-footer {
  height: 70px;
  width: 100%;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 16.8px;
  color: rgba(0, 0, 0, 0.5);
}
.web3modal-modal-card .web3modal-modal-card-footer a {
  margin-left: 5px;
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.5);
}
.web3modal-modal-card .web3modal-provider-wrapper {
  width: 320px !important;
}
.web3modal-modal-card .web3modal-provider-description {
  display: none !important;
}
.web3modal-modal-card .web3modal-provider-name {
  font-size: 14px;
}

.react-datepicker {
  font-size: 16px !important;
}

.react-datepicker__current-month {
  font-size: 16px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  transform: translate3d(286px, 540px, 0px) !important;
}

.react-datepicker__month-container {
  width: 312px !important;
}

.react-datepicker__day-name {
  margin: 14px !important;
}

.react-datepicker__day {
  margin: 8px !important;
  width: 20px !important;
  padding: 4px !important;
}

.react-datepicker__triangle {
  left: 286px !important;
}

.react-datepicker__day--selected {
  background-color: #8eddfd !important;
}

.react-datepicker__header {
  background-color: #f4f4f4 !important;
  border-bottom: 1px solid #e6e6e6 !important;
}

.react-datepicker {
  border: 1px solid hsl(240deg 6% 87%) !important;
}

.pagination {
  margin: 15px auto;
  margin-top: 60px;
  margin-left: -60px;
  display: flex;
  list-style: none;
  outline: none;
  font-weight: 500;
  font-family: Inter;
}
.pagination > .active > a {
  background-color: #00a3ff;
  color: #fff;
}
.pagination > li > a {
  padding: 10px 16px;
  outline: none;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #00a3ff;
  outline: none;
  border-radius: 20px;
}
.pagination > li > a,
.pagination > li > span {
  color: #000;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 8px;
}

.pagination {
  justify-content: center;
  font-family: inherit;
  font-weight: 600;
}

.react-tagsinput {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  overflow: hidden;
  padding: 12px 20px;
  position: relative;
}

.react-tagsinput-tag {
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 4px;
  color: #000;
  display: inline-block;
  font-family: sans-serif;
  font-size: 12px;
  margin-right: 8px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: ' ×';
  color: #909090;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #000;
  font-family: sans-serif;
  font-size: 16px;
  outline: none;
}
